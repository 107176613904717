<template>
  <div
    class="
      flex flex-col
      py-12
      sm:px-6
      lg:px-8
      mt-6
      h-full
      bg-hero-pattern bg-cover
      justify-center
    "
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-auto w-48"
        src="https://cdn.enra.app/avarto/Logo.png"
        alt="arvato"
      />
      <h2 class="mt-10 text-center text-3xl font-extrabold text-white">
        Registrierung
      </h2>
    </div>
    <div
      style="max-width: 700px; margin: 2em auto 0 auto"
      class="bg-white py-6 px-4 shadow sm:rounded-lg sm:px-10"
    >
      <form class="space-y-6 form" @submit.prevent="createUser">
        <div
          v-if="success === true"
          class="bg-green-50 border-l-4 border-green-400 p-4"
        >
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: check-circle -->
              <svg
                class="h-5 w-5 text-green-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-sm text-green-700">
                Ihre Registrierung war erfolgreich. Schauen Sie in Ihr E-Mail
                Postfach.
              </p>
            </div>
          </div>
        </div>

        <div id="err" class="hidden bg-red-50 border-l-4 border-red-400 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: x-circle -->
              <svg
                class="h-5 w-5 text-red-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <p id="err-text" class="text-sm text-red-700"></p>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6 sm:col-span-3">
            <div>
              <label
                for="fname"
                class="block text-sm font-medium text-gray-700"
              >
                Vorname*
              </label>
              <div class="mt-1">
                <input
                  placeholder="First name"
                  id="fname"
                  v-model="firstname"
                  name="fname"
                  type="text"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-3">
            <div>
              <label
                for="lname"
                class="block text-sm font-medium text-gray-700"
              >
                Nachname*
              </label>
              <div class="mt-1">
                <input
                  placeholder="Last name"
                  id="lname"
                  v-model="lastname"
                  name="lname"
                  type="text"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-3">
            <div>
              <label
                for="company"
                class="block text-sm font-medium text-gray-700"
              >
                Unternehmen*
              </label>
              <div class="mt-1">
                <input
                  placeholder="Company"
                  id="company"
                  v-model="company"
                  name="company"
                  type="text"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-3">
            <div>
              <label for="tel" class="block text-sm font-medium text-gray-700">
                Telefonnummer
              </label>
              <div class="mt-1">
                <input
                  placeholder="Telephone number"
                  id="tel"
                  v-model="telephone"
                  name="tel"
                  type="tel"
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6">
            <div>
              <label
                for="email"
                class="block text-sm font-medium text-gray-700"
              >
                E-Mail*
              </label>
              <div class="mt-1">
                <input
                  placeholder="E-Mail"
                  id="email"
                  v-model="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6">
            <div>
              <label
                for="email"
                class="block text-sm font-medium text-gray-700"
              >
                Passwort*<br />
                Das Passwort benötigen Sie am Veranstaltungstag zum Log In
              </label>
              <div class="mt-1">
                <input
                  placeholder="Passwort"
                  id="password"
                  v-model="password"
                  name="password"
                  type="password"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-4">
            <div>
              <label
                for="street"
                class="block text-sm font-medium text-gray-700"
              >
                Straße
              </label>
              <div class="mt-1">
                <input
                  placeholder="Street"
                  id="street"
                  name="street"
                  v-model="street"
                  type="text"
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-2">
            <div>
              <label
                for="housenr"
                class="block text-sm font-medium text-gray-700"
              >
                Hausnummer
              </label>
              <div class="mt-1">
                <input
                  placeholder="Housenumber"
                  id="housenr"
                  v-model="hnr"
                  name="housenr"
                  type="text"
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-1">
            <div>
              <label for="plz" class="block text-sm font-medium text-gray-700">
                Postleitzahl
              </label>
              <div class="mt-1">
                <input
                  placeholder="Postcode"
                  id="plz"
                  v-model="postcode"
                  name="plz"
                  type="text"
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-3">
            <div>
              <label for="city" class="block text-sm font-medium text-gray-700">
                Stadt
              </label>
              <div class="mt-1">
                <input
                  placeholder="City"
                  id="city"
                  v-model="city"
                  name="city"
                  type="text"
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="relative flex items-start">
          <div class="flex items-center h-5">
            <input
              required
              id="data"
              aria-describedby="data-description"
              name="data"
              type="checkbox"
              class="
                focus:ring-indigo-500
                h-4
                w-4
                text-indigo-600
                border-gray-300
                rounded
              "
            />
          </div>
          <div class="ml-3 text-sm">
            <label for="data" class="font-medium text-gray-700"
              >Datenschutzhinweis</label
            >
            <p id="data-description" class="text-gray-500">
              Informationen zur Verarbeitung Ihrer personenbezogenen Daten
              finden Sie
              <a
                href="https://www.arvato-systems.de/datenschutzhinweise-cyber-security-roundtable"
                target="_blank"
                ><b><u>hier</u></b></a
              >
            </p>
          </div>
        </div>
        <button
          type="submit"
          class="
            submit
            w-full
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-medium
            text-white
            bg-avarto
          "
        >
          <div
            class="
              loader
              hidden
              ease-linear
              rounded-full
              border-2 border-t-2 border-gray-200
              h-5
              w-5
              mr-2
            "
          ></div>
          Registrieren
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";

export default {
  name: "Register",
  methods: {
    async createUser() {
      document.querySelector(".loader").classList.remove("hidden");
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then((userCredential) => {
          var user = userCredential.user;
          this.dat = {
            email: this.email,
            firstname: this.firstname,
            lastname: this.lastname,
            postcode: this.postcode,
            city: this.city,
            street: this.street,
            company: this.company,
            telephone: this.telephone,
            hnr: this.hnr,
          };
          firebase
            .firestore()
            .collection("users")
            .doc(user.uid)
            .set(this.dat)
            .then(() => {
              this.sendMail();
            })
            .catch((err) => console.log(err));
        });
    },
    async sendMail() {
      await firebase.functions().httpsCallable("register")(this.dat);
      document.querySelector(".loader").classList.add("hidden");
      this.success = true;
      this.$router.push("/event");
    },
  },
  data() {
    return {
      firstname: "",
      lastname: "",
      company: "",
      telephone: "",
      email: "",
      street: "",
      hnr: "",
      postcode: "",
      city: "",
      password: "",
      success: false,
      dat: {},
    };
  },
};
</script>

<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
